<template>
  <section>
    <b-row>
      <b-col :md="6">
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form class="container">
              <b-row>
                <b-col md="12">
                  <label>Termék *</label>

                  <b-form-group>
                    <v-select
                      v-model="selectedProduct"
                      label="name"
                      :options="allProducts()"
                      @input="selectProductId($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Mennyiség *</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      mode="passive"
                    >
                      <b-input-group :append="unit">
                        <b-form-input
                          v-model="formData.qty"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Nettó beszerzési egységár *</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      mode="passive"
                    >
                      <b-form-input
                        v-model="formData.unit_price"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <label>Raktár *</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedWarehouse"
                      label="name"
                      :options="allWarehouses()"
                      @input="selectWarehouseId($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Dokumentum sorszáma (pl.: számlasorszám)</label>
                    <b-form-input
                      v-model="formData.document_number"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <!-- submit button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Mentés
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col :md="6">
        <b-card>
          <h3>Kiválasztott termék adatai:</h3>
          <b-row>
            <b-col>Név:</b-col>
            <b-col v-if="selectedProduct">{{ selectedProduct.name }}</b-col>
          </b-row>
          <b-row>
            <b-col>Kategória:</b-col>
            <b-col v-if="selectedProduct">{{
              selectedProduct.categories[0].name
            }}</b-col>
          </b-row>
          <b-row>
            <b-col>Egység ár:</b-col>
            <b-col v-if="selectedProduct"
              >{{ selectedProduct.unit_price }} Ft</b-col
            >
          </b-row>
          <b-row>
            <b-col>Nettó ár:</b-col>
            <b-col v-if="selectedProduct"
              >{{ selectedProduct.net_sale_price }} Ft</b-col
            >
          </b-row>
          <b-row>
            <b-col>Bruttó ár:</b-col>
            <b-col v-if="selectedProduct">
              <b-row v-for="priceByWarehouse in selectedProduct.pricesByWarehouses">
                <b-col>{{ priceByWarehouse.warehouse_name }}:</b-col>
                <b-col>{{ priceByWarehouse.price }} Ft
                  <span v-if="priceByWarehouse.override">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="size-2" style="color:red; width: 20px">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                    </svg>
                </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <hr>
          </b-row>
          <b-row>
            <b-col>Raktárkészlet:</b-col>
            <b-col v-if="selectedProduct">
              <b-row v-for="warehouse in selectedProduct.quantity">
                <b-col>{{ warehouse.in }}:</b-col>
                <b-col>{{ warehouse.value }} {{ warehouse.unit }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>Aktív:</b-col>
            <b-col v-if="selectedProduct">
                <div v-if="selectedProduct.active" class="badge badge-success">Aktív</div>
                <div v-else class="badge badge-danger">Inaktív</div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="12">
        <b-card>
          <b-overlay :show="loading">
            <app-timeline>
              <app-timeline-item
                v-for="(incomeLog, index) in allIncomeLogs()"
                :key="index"
                :title="incomeLog.product.name"
                :subtitle="`Mennyiség: ${incomeLog.quantity}, Beszerzési egységár: ${incomeLog.unit_price} ,Raktár: ${incomeLog.warehouse.name}, Felhasználó: ${incomeLog.user.name}`"
                :time="incomeLog.created_at.readable"
              />
            </app-timeline>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import vSelect from "vue-select";
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";

export default {
  name: "InwardHandling",
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      formData: {
        product_id: null,
        qty: null,
        unit_price: null,
        warehouse_id: null,
        document_number: null,
      },
      selectedProduct: null,
      selectedWarehouse: null,
      unit: null,
      loading: false,
      required,
    };
  },
  methods: {
    ...mapActions([
      "createIncomeLog",
      "fetchIncomeLogs",
      "fetchProducts",
      "fetchWarehouses",
    ]),
    ...mapGetters(["allProducts", "allIncomeLogs", "allWarehouses"]),
    initProducts() {
      this.loading = true;
      this.fetchProducts().then((res) => {
        this.loading = false;
      });
    },
    initWarehouses() {
      this.loading = true;
      this.fetchWarehouses().then((res) => {
        this.loading = false;
      });
    },
    initIncomeLogs() {
      this.loading = true;
      this.fetchIncomeLogs().then((res) => {
        this.loading = false;
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.createIncomeLog(this.formData).then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sikeres mentés",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.formData.product_id = null;
            this.formData.qty = null;
            this.formData.unit_price = null;
            this.formData.warehouse_id = null;
            this.formData.document_number = null;
            this.selectedProduct = null;
            this.selectedWarehouse = null;
            this.initIncomeLogs();
            this.initProducts();
          });
        }
      });
    },
    selectProductId(e) {
      this.formData.product_id = e.id;
      this.unit = e.unit;
    },
    selectWarehouseId(e) {
      this.formData.warehouse_id = e.id;
    },
  },
  created() {
    this.initProducts();
    this.initWarehouses();
    this.initIncomeLogs();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
